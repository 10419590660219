import { DateTime } from "luxon";
import moment from "moment";

import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { VALID_DAYS } from "@smartrr/shared/entities/Organization";

export const billingDaysChoices = [
  { label: "Monday", value: "mon" },
  { label: "Tuesday", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
  { label: "Sunday", value: "sun" },
];

export const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export function nowUTC(): Date {
  return nowUTCLuxon().toJSDate();
}

/**
 * Returns the current date and time (rounded down to the nearest hour) in UTC format (YYYY-MM-DDTHH:00:00Z).
 *
 * @returns {string} The current date and time in UTC format.
 */
export function currentUTCDateRoundedToHour(): string {
  const date = new Date();
  date.setMinutes(0, 0, 0); // Round down to the nearest hour
  return date.toISOString();
}

export function nowUTCLuxon(): DateTime {
  return toUTCDateLuxon(new Date());
}

export function toUTCDate(date: Date): Date {
  return toUTCDateLuxon(date).toJSDate();
}

export function toUTCDateLuxon(date: Date): DateTime {
  // setting milliseconds to zero, a bunch of annoying equality differences around milliseconds
  return DateTime.fromJSDate(new Date(date)).toUTC().set({ millisecond: 0 });
}

export function toUTCDateLuxonFromUTCMilliseconds(utcMilliseconds: number): DateTime {
  return DateTime.fromMillis(utcMilliseconds, { zone: "UTC" }).set({ millisecond: 0 });
}

export function toUTCDateLuxonFromUTCString(utcIsoString: string): DateTime {
  return DateTime.fromISO(utcIsoString, { zone: "UTC" }).set({ millisecond: 0 });
}

export function timeZoneDateToUTC(timeZoneDate: string, timeZone: string): Date {
  return DateTime.fromISO(timeZoneDate, {
    zone: timeZone,
  })
    .toUTC()
    .toJSDate();
}

export function fromShopifyDateTime(shopifyDateTime: any): Date {
  return new Date(shopifyDateTime);
}

// for front end only
export function utcDateToLocalTime(utcDate: string | Date): Date {
  return new Date(utcDate);
}

export function getDaysBetweenDates(earlierDate: Date, laterDate: Date): number;
export function getDaysBetweenDates(earlierDate: DateTime, laterDate: DateTime): number;

export function getDaysBetweenDates(earlierDate: Date | DateTime, laterDate: Date | DateTime): number {
  let utc1 = 0;
  let utc2 = 0;

  if (earlierDate instanceof DateTime && laterDate instanceof DateTime) {
    utc1 = Date.UTC(earlierDate.year, earlierDate.month - 1, earlierDate.day);
    utc2 = Date.UTC(laterDate.year, laterDate.month - 1, laterDate.day);
  } else {
    utc1 = Date.UTC(
      (earlierDate as Date).getFullYear(),
      (earlierDate as Date).getMonth(),
      (earlierDate as Date).getDate()
    );
    utc2 = Date.UTC(
      (laterDate as Date).getFullYear(),
      (laterDate as Date).getMonth(),
      (laterDate as Date).getDate()
    );
  }

  return Math.floor((utc2 - utc1) / MILLISECONDS_IN_DAY);
}

export function dropTimeFromDate(date: Date | ISODateString): string {
  const newDate = new Date(date);
  return newDate.toISOString().split("T")[0];
}

export function dayOfYear(month: number, monthDay: number): number | undefined {
  const currentYear = DateTime.utc().year;
  const yearDay = DateTime.utc(currentYear, month, monthDay).ordinal;

  // Incorrect date (e.g. February 30 or June 31) results in yearDay === NaN, in this case we return undefined
  return Number.isFinite(yearDay) ? yearDay : undefined;
}

export function isSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function getMaxDate(date1: Date, date2: Date): Date {
  const date1Ms = +toUTCDate(date1);
  const date2Ms = +toUTCDate(date2);

  return date1Ms > date2Ms ? date1 : date2;
}

export function getShortenedDayString(date: Date) {
  return date.toLocaleDateString("en", { weekday: "short" }).toLowerCase();
}

export function adjustToStoreBillingTime(date: Date, storeBillingTime: string, storeBillingTimezone: string) {
  const [hours = 0, minutes = 0, seconds = 0] = storeBillingTime.split(":");
  const selectedDate = DateTime.fromJSDate(date);

  const merchantDateTime = DateTime.fromObject(
    {
      // Date comes in as selected in the calendar
      year: selectedDate.year,
      month: selectedDate.month,
      day: selectedDate.day,

      // Time and time zone come from merchant's settings
      hour: +hours,
      minute: +minutes,
      second: +seconds,
    },
    {
      zone: storeBillingTimezone,
    }
  );

  return merchantDateTime.toJSDate().toISOString();
}

export const isIsoDate = (str: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
    return false;
  }
  const d = new Date(str);
  return d instanceof Date && d.toISOString() === str; // valid date
};

export const isLeapYear = (year: number) => {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
};

export const isDayInArrOfDates = (arr: Date[], checkDate: Date) =>
  arr.some(date => moment(resetTimeOnDate(date)).isSame(moment(checkDate), "day"));

export function updateDateRecur(arr: Date[], date: Date): Date {
  const copyOfDate = new Date(date.valueOf());
  if (isDayInArrOfDates(arr, copyOfDate)) {
    copyOfDate.setDate(copyOfDate.getDate() + 1);
    return updateDateRecur(arr, copyOfDate);
  }
  return copyOfDate;
}

export function incrementDateRecurByBillingDay(orgBillingDays: string[], date: Date): Date {
  const copyOfDate = new Date(date.valueOf());
  const disabledBillingDays = billingDaysChoices
    .map(({ value }) => value)
    .filter(value => !orgBillingDays.includes(value));
  if (disabledBillingDays.includes(getShortenedDayString(date))) {
    copyOfDate.setDate(copyOfDate.getDate() + 1);
    return incrementDateRecurByBillingDay(orgBillingDays, copyOfDate);
  }
  return copyOfDate;
}

export function validateBillingDays(billingDays?: string[]): boolean {
  if (!billingDays) {
    return true;
  }
  for (const day of billingDays) {
    if (!VALID_DAYS.includes(day.toLowerCase())) {
      return true;
    }
  }
  return false;
}

/**
 * Sets the date's time to 0:00.
 * Used to disable today's date on date pickers.
 * @param {Date} date - The date, that's used as a base for the return value.
 */

export const resetTimeOnDate = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

/**
 * Sets the date's time to 23:59:59.
 * Used to disable today's date on date pickers.
 * @param {Date} date - The date, that's used as a base for the return value.
 */

export const setDateTimeToLastSecond = (date: Date) => {
  const newDate = new Date(date);
  newDate.setHours(23);
  newDate.setMinutes(59);
  newDate.setSeconds(59);
  return newDate;
};

export const getMockDisabledDays = (selectedBillingDays: string[], day: Date) => {
  if (validateBillingDays(selectedBillingDays)) {
    throw new Error("Invalid Billing Days");
  }
  return getInvalidBillingDays(selectedBillingDays, day);
};

export const areDatesOnSameDay = (a: Date, b: Date) =>
  resetTimeOnDate(a).getTime() === resetTimeOnDate(b).getTime();

// To be used within date pickers for disabling exact dates in future
export const getInvalidBillingDays = (selectedBillingDays: string[], day: Date, omitDates?: Date[]): Date[] => {
  // grabbing the deselected billing days, the inverse
  const disabledBillingDays = billingDaysChoices
    .map(day => day.value)
    .filter(day => !selectedBillingDays.includes(day));

  let disabledDates: Date[] = [];

  const startDate = moment(day, moment.ISO_8601, true);
  // setting end to be 6 months in the future - 6 months being the max for pause duration
  const endDate = moment(startDate).add(6, "M").endOf("month");

  if (disabledBillingDays) {
    disabledBillingDays.forEach(async day => {
      const d = moment(startDate).startOf("week").day(day);
      // If the endDate is somehow before the first occurrence of the selected day, skip this day
      if (moment(endDate).isBefore(d)) {
        return;
      }

      // Add the first occurrence of the selected day within the date range to disabledDates
      disabledDates.push(d.toDate());

      // Add subsequent occurrences of the selected day until reaching the endDate
      while (d.isBefore(endDate)) {
        d.add(7, "d");
        disabledDates.push(d.toDate());
      }
    });
  }

  if (omitDates) {
    disabledDates = disabledDates.filter(date => {
      return !isDayInArrOfDates(omitDates, date);
    });
  }

  return disabledDates.sort((a, b) => a.valueOf() - b.valueOf());
};

export const toMonthDay = (date: Date): string => {
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

/**
 * Returns the number of days in a given month, ignoring leap years,
 * or 0 if an invalid month is provided. Please don't change this
 * function to consider leap years, as it's used in areas where that doesn't
 * make sense.
 */
export function daysInMonthNoLeapYear(month: number): number {
  return (
    {
      1: 31,
      2: 28, // ignore leap year
      3: 31,
      4: 30,
      5: 31,
      6: 30,
      7: 31,
      8: 31,
      9: 30,
      10: 31,
      11: 30,
      12: 31,
    }[month] ?? 0
  );
}
